import validate from "/var/lib/jenkins/workspace/Master/node_modules/nuxt/dist/pages/runtime/validate.js";
import navitem_45global from "/var/lib/jenkins/workspace/Master/middleware/navitem.global.ts";
import sidebar_45global from "/var/lib/jenkins/workspace/Master/middleware/sidebar.global.ts";
import manifest_45route_45rule from "/var/lib/jenkins/workspace/Master/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  navitem_45global,
  sidebar_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}